import * as React from "react";

export const Przelewy24Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 1920 672"
    {...props}
  >
    <path
      fill="#D13239"
      fillRule="evenodd"
      d="m409.6 384.9 156.4-.2-5.7 35.6-130.8 124.9 109.8-.2-6.4 38.4-161.7.1 6.8-39.7 125.4-120.1H403.3l6.3-38.8zM238.5 324c-3.4-3.6-8.3-6.4-13.7-8.6-5.7-2.2-12-3.8-18.2-4.9-7.8-1.4-15.4-2-20.8-2.2-5-.2-8.2-.2-8.2-.2H58.3L12.7 583.5h42.6l16.8-102 82.6.4s32.2 1.3 55-10.8c22.8-12.1 28.9-39.6 28.9-39.6s2.5-10.2 4.6-22.6c2.3-13.7 4.6-30.1 5.8-38.8.5-3.8.8-6.2.8-6.2s.6-2.8.6-7.2c.1-6.1-.8-15.3-5.6-24.1-1.7-3.1-3.7-5.9-6.3-8.6zm-32.8 48.6c0 .5-3.8 22.6-8.8 48.8-1.9 10-9.6 15.4-18.4 17.3-14.6 3.1-30.3 2.5-30.3 2.5l-69-.2 15.2-91.5 62.5.2s4.2-.1 10.1 0c6.7.1 15.6.4 22.9 1.4 6.3.8 11.3 2.1 12.9 4.1 2.1 2.6 2.9 6 3.2 9 .4 4.4-.3 8.1-.3 8.4zM778.1 308.1h41l-45.9 275.4-41.1-.1 46-275.3zM996.9 384.9l39.3-.1 13.9 139.8 60.5-140 48.5.1 14.3 140.4 60.4-140.3h40.8L1188 583.3h-48.4l-14-139-61.1 139-47.6.2-20-198.6z"
      clipRule="evenodd"
    />
    <g fill="#D13239">
      <path d="M702.4 386.5c-11.5-3.7-31.4-4.5-48.3-4.3-16.3.2-23.8 1-29.9 2.3 0 0-29 4.2-45.5 24.6-16.5 20.4-21.4 65-21.4 65s-9.8 49.3-6.9 65.8c2.9 16.4 8 31.7 26.6 38.8 18.6 7.2 34.4 6.8 34.4 6.8s33.2 2.6 58.2-3.3 38.2-23.5 38.2-23.5 5.9-7.6 10.1-16.6c4.2-9 5.5-15.3 5.7-16.1l2.6-10.7-42.5.1s-2.3 28.1-25.3 30.7c-22.9 2.6-35.2 1.6-39.7 1.4-4.4-.2-29 .9-27-19.7 0-.3 0-.7.1-1.3 1.1-23.4 3.7-29.5 3.7-29.5l133.5-.4 5.7-33c6.6-37.4 1.9-65.9-32.3-77.1zm-8.9 72.4-91.5-.1 3.6-14.5s3.2-11.4 9.5-16.2c6.4-4.8 14.5-5.7 22.1-6.4 7.6-.7 27.9-2.2 44.4 1.2 5.5 1.1 10.7 4.2 12.1 8.8 3.2 10.8-.2 27.2-.2 27.2z" />
      <path d="M592 526.9c0 .3-.1.6-.1.9-.1 1.6.1-1 .1-.9z" />
    </g>
    <g fill="#D13239">
      <path d="M843.8 527c0 .3-.1.6-.1.9 0 1.6.1-1 .1-.9z" />
      <path d="M954.3 386.6c-11.5-3.7-31.4-4.5-48.3-4.3-16.3.2-23.8 1-29.9 2.3 0 0-29 4.2-45.5 24.6-16.5 20.4-21.4 65-21.4 65s-9.8 49.3-6.9 65.8c2.9 16.4 8 31.7 26.6 38.8 18.6 7.2 34.4 6.8 34.4 6.8s33.2 2.6 58.2-3.3 38.2-23.5 38.2-23.5 5.9-7.6 10.1-16.6c4.2-9 5.5-15.3 5.7-16.1l2.6-10.7-42.5.1s-2.3 28.1-25.3 30.7c-22.9 2.6-35.2 1.6-39.7 1.5-4.4-.2-29 .8-27-19.7 0-.3 0-.7.1-1.3 1.1-23.4 3.7-29.5 3.7-29.5l133.5-.4 5.7-33c6.6-37.5 1.9-66.1-32.3-77.2zm-9.3 72.3-91.5-.1 3.6-14.5s3.2-11.4 9.5-16.2c6.3-4.8 14.5-5.7 22.1-6.4 7.6-.7 27.9-2.2 44.4 1.2 5.5 1.1 10.7 4.2 12.1 8.8 3.2 10.7-.2 27.2-.2 27.2z" />
    </g>
    <path
      fill="#D13239"
      fillRule="evenodd"
      d="m1321.8 384.9 26.3 145.2 74.3-145.3 41.8.4-107.1 207.5s-19.4 37.6-31.4 47.1-19.4 13.8-29.2 14.8-13.8 1.7-23.2 0l-10-1.8 6.2-37.1s16.6 3.1 26.4-.8c9.9-3.9 17.8-20.7 17.8-20.7l5-8.4-38.7-201 41.8.1z"
      clipRule="evenodd"
    />
    <path
      fill="#B3B2B1"
      fillRule="evenodd"
      d="m1479.4 404.6 43.1.1 2.6-16.7s4.6-30.2 14.9-36c3.3-1.9 8.6-3.6 14.7-4.7 11.3-2 25.6-2.2 37.3-1.8 17.9.6 24.7.8 42.9 2.9 18.2 2.1 13.6 19.7 13.6 19.7l-3.6 26.3s-1.6 11.8-5.8 19.1c-3.7 6.5-13.9 10.9-19.8 12.8-14.2 4.6-62.8 17-62.8 17l-38.2 11s-23.5 6.8-36.6 21.3c-13.2 14.6-18.4 31.1-20.2 39.7-1.8 8.6-11.9 68.1-11.9 68.1l206.2.1 6.9-41.2-163.1.2 2.9-16.8s1.9-17.3 8.9-23c2.2-1.8 3.3-4.2 16.3-8.8 7.8-2.8 34.4-9.9 34.4-9.9l61.5-16.8s33.6-8.6 46.8-27c13.2-18.3 18.3-53.4 18.3-53.4s3.6-34.1.8-44.8c-2.7-10.7-12.8-23.5-25.1-29s-25.1-8.7-62.2-8.2-55.5 2.2-74.3 9.2c-18.8 6.9-29.7 19.5-36.6 37.3-7.5 16.9-11.9 53.3-11.9 53.3zM1867.6 481.2l28.9-173.2h-51.2l-159.4 171.2-7.2 43.4h139.4l-10.2 60.8 42.8.1 10.1-60.9 39.5.1 7.1-41.5h-39.8zm-42.6.1-90.1-.1 109.7-116.9-19.6 117zM328.9 330.8h131.3s29.4-23.8 50.3-39.1 58.9-39.4 58.9-39.4l-74.2-34.7s-62.7 38.8-89.3 57.1c-25.8 16.9-77 56.1-77 56.1zM611.1 227.8l-60.9-41s55.1-31.3 128.4-60.5c73.2-29.2 112.3-41.4 112.3-41.4l12.4 58.1s-70.5 23.6-110.9 42.7c-41.8 17.8-81.3 42.1-81.3 42.1zM848.3 130.3l-10.6-59.5s75.2-20 144-32.8c68.9-12.8 160.2-18.9 160.2-18.9l-30.2 91.9s-80.3-11-155.7-.7c-58.7 7-107.7 20-107.7 20zM1159.5 118.8l50.8-102.1s111.3-2.2 207.3 12.7c96 14.8 183.8 37.6 181.9 38.6l-243.4 127.3s-56.9-36-127.5-58.1c-40-11.8-69.1-18.4-69.1-18.4zM1399.7 223l53.5 40.6h439.7s-.9-14.2-12.6-34.4c-7.3-12.6-20.6-26-34.5-39.9-5.1-5-25.3-20.8-40.5-30.7-38.8-25.2-60.5-34.9-100.8-53.2L1399.7 223z"
      clipRule="evenodd"
    />
    <path
      fill="#D13239"
      d="M357.4 384.7c-16.5 0-32.1 6.5-45.4 13.8l2.3-13.8h-43.7l-35 197.7h43.8l19.4-109.5c4-22.2 20.6-49.7 53-49.7l22.6-.1 6.8-38.4h-23.8z"
    />
  </svg>
);
